<template>
  <div id="WjForm">
    <van-form ref="form" class="form">
      <!-- 基本信息 -->
      <van-cell-group title="基本信息">
        <van-field v-model="form.stuName" readonly placeholder="点击此处选择违纪学生" :required="true" :rules="[{ required: true  } ]" @click-input="selectStu" label="姓名" />
        <van-field v-model="form.stuNo" placeholder="请选择学生" :required="true" :rules="[{ required: true  } ]" readonly label="学号" />
        <van-field v-model="form.gradeNo" placeholder="年级" readonly label="年级" />
        <van-field v-model="form.unitName" placeholder="院系" readonly label="院系" />
        <van-field v-model="form.specName" placeholder="专业" readonly label="专业" />
        <van-field v-model="form.className" placeholder="班级" readonly label="班级" />
      </van-cell-group>
      <!-- 基本信息 -->
      <van-cell-group title="违纪信息">
        <custom-select ref="schYear" v-model="form.schoolYear" :required="true" placeholder="请选择学年" label="学年" :columns="dict.xj_nj" />
        <van-field v-model="form.issueNumber" placeholder="请填写发文编号" :rules="[{ required: true  } ]" label="发文编号" />
        <custom-date-picker ref="disciplinaryAt" v-model="form.disciplinaryAt" label="违纪时间" :rules="[{ required: true  } ]" :required="true" placeholder="请选择违纪时间" />
        <custom-select ref="confirmResult" v-model="form.confirmResult" :required="true" placeholder="请选择认定结果" label="认定结果" :columns="dict.disciplinary_punish_behavior" />
        <custom-select ref="punishResult" v-model="form.punishResult" :required="true" placeholder="请选择处分结果" label="处分结果" :columns="dict.disciplinary_punish_res" />
        <custom-select ref="punishAt" v-model="form.punishAt" :required="true" placeholder="请选择处分期限" label="处分期限" :columns="dict.disciplinary_punish_date" />
        <van-field v-model="form.disciplinarySituation" rows="4" :required="true" :rules="[{ required: true  } ]" class="textarea-style" placeholder="请填写违纪情况" arrow-direction="" label="违纪情况说明" type="textarea" maxlength="300" />
      </van-cell-group>
      <van-cell-group title="处分材料" class="upload-file">
        <file-upload v-if="refsh" accept=".doc,.docx,.pdf,.xls,.xlsx,.png,.gif,.jpeg" v-model="form.materialFileId" miz="STU_PUNISH_RESOLVE" biz="storage" :multiple="3" v-on:uploadResponse="uploadResponse" list-type="list" :auto-upload="true" :max-size="1">
          <span slot="tip" class="upload-tips">
            支持上传任意文件,文件最大1M
          </span>
        </file-upload>
      </van-cell-group>
    </van-form>
    <van-popup v-model="showChangeStu" closeable position="bottom" :style="{ height: '80%', backgroundColor:'#F5F5F5' }">
      <div class="wj-title">违纪学生选择</div>
      <van-search class="wj-search" v-model="searchValue" @input="onSearchHandle" placeholder="请输入学生校园卡号或姓名检索" />
      <div class="wj-body">
        <van-cell v-for="(row,index) in stuData" :key="index" class="stu-card">
          <template #title>
            <span style="font-size:15px;font-weight:bold"> {{row. stuName +"  " +row. stuNo}}</span>
          </template>
          <template #label>
            <span style="font-size:14px;">{{ row.sqecName +'-'+  row.className}}</span>
          </template>
          <template #right-icon>
            <van-button class="table-edit-btn" @click="selectRow(row)" plain round size="small" type="primary">选择</van-button>
          </template>
        </van-cell>
      </div>
    </van-popup>
    <div class="submit-button shadow-style">
      <div>
        <van-button round block type="primary" :loading="loading" @click="doSubmit()">提 交</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { codeConvertLabel } from "@/utils";
import fileUpload from "@/components/FileUpload";
import { getStuinfoNotPage } from '@/api/modules/xj/stuInfo'
import { validCustom } from "@/utils/index";
import { add, edit, getDtoById } from '@/api/modules/daily/disciplinary'

export default {
  name: "DisciplinaryDeatil",
  dicts: ["disciplinary_punish_res", "disciplinary_punish_date", 'disciplinary_punish_behavior', 'xj_nj'],
  components: { fileUpload },
  data() {
    return {
      form: {
        id: '',
        stuName: null,
        stuNo: null,
        sex: null,
        nationality: null,
        unitNo: null,
        unitName: null,
        specCode: null,
        specName: null,
        classNo: null,
        className: null,
        phone: null,
        schoolYear: null,
        conslrStaffNo: null,
        conslrStaffName: null,
        status: 1,
        files: null,
        disciplinaryAt: null,
        issueNumber: null,
        disciplinarySituation: null,
        confirmResult: null,
        punishResult: null,
        punishAt: null,
        materialFileId: [],
        cancelRemark: null,
      },
      loading: false,
      showChangeStu: false,
      searchValue: "",
      stuData: [],
      refsh: true,
      customRuleFileds: ["punishAt", "punishResult", "confirmResult", "schYear", "disciplinaryAt"],
    }
  },
  computed: {
    ...mapGetters(["user", "roles"]),
  },
  created() {
    if (this.$route.query && this.$route.query.id) {
      getDtoById(this.$route.query.id).then((res) => {
        this.form = { ...res }
        if (res.materialFiles) {
          this.form.materialFileId = JSON.parse(res.materialFiles)
          this.refsh = false
          this.$nextTick((e) => {
            this.refsh = true
          })
        }
      })
    }

  },
  methods: {
    codeConvertLabel,
    doSubmit() {
      this.$refs.form.validate().then(
        (res) => {
          if (validCustom(this.customRuleFileds, this)) {
            if (this.form.materialFileId.length <= 0) {
              this.$notify({
                type: "warning",
                message: "请上传违纪材料",
                duration: 1500,
              });
              return;
            }
            // 验证通过数据提交
            this.$dialog
              .confirm({
                title: "提示",
                message: "你确定要进行提交操作吗？",
              })
              .then(() => {


                this.loading = true;
                if (this.$route.query && this.$route.query.id) {
                  this.doEdit();
                } else {
                  this.doAdd();
                }
              })
              .catch(() => {
                // on cancel
              });
          } else {
            this.$notify({
              type: "warning",
              message: "有提交项未填写！",
              duration: 1500,
            });
          }
        },
        (err) => {
          validCustom(this.customRuleFileds, this);
          this.$notify({
            type: "warning",
            message: "有提交项未填写！",
            duration: 1500,
          });
        }
      );
    },
    doAdd() {
      add(this.form)
        .then((res) => {
          this.loading = false;
          this.$notify({
            type: "success",
            message: "操作成功",
            duration: 2000,
          });
          this.cancel();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    doEdit() {
      this.loading = false;
      edit(this.form)
        .then((res) => {
          this.loading = false;
          this.$notify({
            message: "操作成功",
            type: "success",
            duration: 2000,
          });
          this.cancel();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    cancel() {
      this.showChangeStu = false
      this.searchValue = ""
      this.stuData = []
      this.form = {
        id: '',
        stuName: null,
        stuNo: null,
        sex: null,
        nationality: null,
        unitNo: null,
        unitName: null,
        specCode: null,
        specName: null,
        classNo: null,
        className: null,
        phone: null,
        schoolYear: null,
        conslrStaffNo: null,
        conslrStaffName: null,
        status: 1,
        files: null,
        disciplinaryAt: null,
        issueNumber: null,
        disciplinarySituation: null,
        confirmResult: null,
        punishResult: null,
        punishAt: null,
        materialFileId: [],
        cancelRemark: null,
      }
      this.$router.go(-1)
    },
    selectStu() {
      this.showChangeStu = true
    },
    selectRow(stuInfo) {
      this.form.unitNo = stuInfo.collNo // 院系代码
      this.form.unitName = stuInfo.collName // 院系名称
      this.form.specCode = stuInfo.sqecCode // 专业代码
      this.form.specName = stuInfo.sqecName // 专业名称
      this.form.classNo = stuInfo.classNo // 班级号
      this.form.className = stuInfo.className // 班级名称
      this.form.stuNo = stuInfo.stuNo // 学号
      this.form.stuName = stuInfo.stuName // 姓名
      this.form.sex = stuInfo.sexCode // 性别码
      this.form.sexName = stuInfo.sexName // 性别名称
      this.form.cardId = stuInfo.cardNo // 身份证号
      this.form.nationality = stuInfo.nationCode //民族
      this.form.politicalStatus = stuInfo.polCode //政治面貌
      this.form.gradeNo = stuInfo.gradeNo
      this.form.conslrStaffName = stuInfo.conslrStaffName //辅导员姓名
      this.form.conslrStaffNo = stuInfo.conslrStaffNo //辅导员卡号
      this.form.bornTime = stuInfo.bdDate // 出生日期
      this.form.admissionTime = stuInfo.enterMon //入学年月
      this.showChangeStu = false
    },
    onSearchHandle(queryString) {
      getStuinfoNotPage({ stu: queryString }).then((stulist) => {
        if (stulist && stulist.length) {
          this.stuData = stulist
        } else {
          this.stuData = []
        }
      })
    },
    uploadResponse(fileIds) {
      this.form.materialFileId = fileIds
    },
  }
}
</script>

<style lang="scss" scoped>
#WjForm {
  .form {
    ::v-deep.radio-other-info {
      .van-cell {
        .van-field__label {
          width: 11.2em;
        }
      }
    }

    .van-field__label {
      width: 110px;
    }
    .van-cell {
      font-size: 15px;
      line-height: 32px;
      height: 50px;
    }
    .textarea-style {
      height: 100%;
    }
  }

  .wj-title {
    text-align: center;
    line-height: 55px;
    font-size: 17px;
    font-weight: bold;
    height: 40px;
    background-color: #fff;
  }
  .wj-search {
  }
  .stu-card {
    margin-top: 18px;
  }
  .wj-body {
    overflow-y: scroll;
    height: calc(80vh - 100px);
    padding: 0 18px;
  }
}
</style>s